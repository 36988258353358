.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-in-animation {
  animation-name: slideIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes slideIn {
  from {margin-top: 100vh;}
  to {margin-top: 0;}
}

.analysis-fade-out-animation {
  animation-name: analysisFadeOut;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes analysisFadeOut {
  from {
    opacity: 1;
    bottom: 0;
  }
  to {
    opacity: 0;
    bottom: 0;
  }
}

#analyse-headline {
  font-size: 60px;
}

.subtext {
  font-size: 24px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.girl-image {
  margin-right: 60px;
  filter: drop-shadow(5px 5px 5px black);
  width: 17%;
  max-width: 150px;
}

.file-image {
  margin-right: 5px;
  filter: drop-shadow(5px 5px 5px black);
  width: 7%;
  max-width: 50px;
}

.computer-image {
  margin-left: 80px;
  filter: drop-shadow(5px 5px 5px black);
  width: 17%;
  max-width: 200px;
}

.results-table-container {
  width: 100%;
  max-width: 700px;
  margin-top: 15px;
}

.results-table {
  width: 100%;
}

.results-table tr {
  display: none;
}

.results-table td {
  padding: 0 7px;
}

.result-text {
  text-align: center;
  margin: 0;
  font-size: 16px;
}

.result-icon {
  font-size: 26px;
}
