.result-headline {
  font-size: 60px;
  text-align: center;
  margin: 0;
}

.fireworks-image {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
}

.sunglasses-image {
  position: absolute;
  top: 25%;
  left: 50%;
  opacity: 0;
  transform: rotateZ(150deg);
  z-index: 1;
}

.manon-image-container {
  position: relative;
  display:flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
}

.slide-sunglasses-animation {
  animation-name: slide-sunglasses;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes slide-sunglasses {
  from {
    opacity: 0;
  }
  to {
    top: 33%;
    transform: translateX(-50%) rotateZ(0);
    opacity: 1;
  }
}
