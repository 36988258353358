* {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(50, 50, 50);
  overflow: hidden;
}

.fade-out-animation {
  animation-name: fadeOut;
  animation-duration: 1.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

@keyframes fadeOut {
  from {opacity: 1; display: flex;}
  to {opacity: 0; display:none}
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
}

.manon-image {
  filter: drop-shadow(5px 5px 5px #111);
}

.headline {
  font-size: 40px;
}

.main-button {
  margin-top: 15px;
  padding: 10px 30px;
  font-size: 26px;
  border-radius: 10px;
  border: 2px solid rgb(255, 255, 255);
  background-color: rgb(112, 0, 0);
  box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  width: 280px;
  max-width: 100%;
  transition: 0.5s, transform 0.1s;
}

.main-button span {
  position: relative;
  transition: 0.5s;
}

.main-button span:after {
  content: "\00bb";
  position: absolute;
  top: 0;
  opacity: 0;
  right: -20px;
  transition: 0.5s;
}

.main-button:hover span {
  padding-right: 25px;
}

.main-button:hover span:after {
  opacity: 1;
  right: 0;
}

.main-button:active {
  transition: 0s;
  transform: translateY(2px);
}

.invisible-link {
  display: none;
}

button:focus {outline:0;}